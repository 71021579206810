body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  font-family: 'Noto Sans', 'Noto Sans HK', 'Noto Sans TC', 'Noto Sans SC', 'Noto Sans JP', 'Noto Sans KR',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --app-primary: #00ae68;
  --app-primary-text: #00ae68;
  --bs-primary: var(--app-primary);
  --bs-primary-rgb: var(--app-primary);
  --bs-primary-bg-subtle: #098855;
  --bs-primary-border-subtle: #9ecfbb;
  --bs-primary-text-emphasis: #004e68;
  --app-background: #f2f2f2;
  --app-btn-active: #ddd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#content-container {
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--app-background);
  padding: 1rem;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: var(--app-background) !important;
}

.ant-form-item-label > label {
  font-weight: bold;
  color: var(--app-primary-text);
}

.disabled-grey-bg {
  color: #ccc;
}
.disabled-grey-bg:hover {
  color: #888;
}
h1 {
    color: var(--app-primary);
}
.SideBar {
    .side-color-block {
        min-width: .3rem;
        height: 100vh;
        background-color: var(--app-primary);
    }
    .navigator-area {
        height: 100%;
        overflow-y: auto;
        > div {
            border-radius: .5rem 0 0 .5rem;
            padding: .3rem;
            width: 80%;
            cursor: pointer;
            user-select: none;
        }
        > div:hover {
            background-color: var(--app-background);
        }
        > div:active {
            background-color: var(--app-btn-active);
        }
        > div.active {
            background-color: var(--app-background);
            cursor: not-allowed;
        }
    }
    .circle-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        > * {
            display: block;
        }
        height: 96px;
        width: 96px;
        border: solid 1px var(--app-primary);
        border-radius: 50%;
    }
    color: var(--app-primary);
    font-size: 0.8rem;
    height: 100vh;
    max-height: 100vh;
    width: 10rem;
    overflow: hidden;
}